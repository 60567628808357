/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap"); */

@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
button {
  cursor: pointer;
}
body {
  overflow-x: hidden;
}
