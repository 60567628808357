.quizShape {
    clip-path: ellipse(5% 12% at 100% 50%);
}

.quizShape2 {
    clip-path: ellipse(5% 12% at 5% 50%);
}

.quizShape3 {
    clip-path: inset(0 0 0 5%);
}