@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&display=swap");

.training-fonts {
  font-family: "Readex Pro", sans-serif !important;
}

/* .training_boxes {
  background: #FFFFFF;
  box-shadow: 4px 4px 10px rgba(0, 118, 135, 0.05), 6px 6px 40px rgba(0, 118, 135, 0.1);
  border-radius: 5px;
} */

/* .training {
  height: 100vh;
  background-color: #f6f6f6;
}

.training-main {
  height: 90vh;
  display: grid;
  grid-template-columns: 18vw 1fr;
  background-color: #f6f6f6;
} */
