.active {
  display: block;
  color: #fff;
  background: #003a69;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-radius: 5px;
  fill: #fff;
  margin-top: 20px;
}

.inactive {
  display: block;
  color: #171a1a;
  fill: #003a69;
  background: #d9f0fa;
  padding: 15px 20px;
  border-radius: 5px;
  margin-top: 20px;
}
