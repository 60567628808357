.course-content-active{
    color: #00495F;
    border-bottom: 3px solid #00495F;
    border-radius: 2px;
    margin-bottom: -3px;
    display: block;
}

.course-content-inactive{
    color: #007C84;
}