.searchBox form .form {
  width: 300px;
  display: flex;
  align-items: center;
  gap: 15px;
  background: linear-gradient(to right, #00768714, #0076870f, #00768705);
  border-radius: 10px;
  padding: 10px 15px;
}

.input {
  color: #007c84;
  font-weight: 600;
  font-size: 16px;
}

.input::placeholder {
  color: #007c84;
}

.searchBox input {
  border: none;
  outline: none;
  background: transparent;
}
