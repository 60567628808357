/* @import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap"); */

@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&display=swap");

.nav {
  width: 100%;
  height: 10vh;
  background-color: rgba(255, 255, 255, 1);
  margin-top: 2vh;
}

.nav-cnt {
  position: relative;
}

.nav-cnt-left-img {
  height: 10vh;
  position: absolute;
  left: 3vw;
  top: 0;
}

.nav-cnt-center {
  position: absolute;
  left: 20vw;
  display: grid;
  place-items: center;
  height: 10vh;
  font-family: "Readex Pro", sans-serif;
  /* font-size: 24px; */
  font-size: 1.6vw;
  font-weight: 800;
  text-transform: capitalize;
}

.nav-cnt-right {
  position: absolute;
  right: 0;
  display: grid;
  grid-template-columns: 1fr 3vw 3vw;
  width: 20vw;
  margin-right: 2.5vw;
  margin-top: 1vh;
}

.nav-cnt-right-profile {
  display: grid;
  grid-template-columns: 4vw auto;
}

.nav-cnt-right-profile-right {
  display: grid;
  justify-content: left;
  align-items: center;
  padding-left: 1vh;
}

.nav-cnt-right-profile-img {
  display: grid;
  place-items: center;
}

.nav-cnt-right-notify {
  display: grid;
  place-items: center;
}

.nav-cnt-right-settings {
  display: grid;
  place-items: center;
  padding: 8px 0 0 0;
}

.nav-cnt-right-profile-right-name {
  font-family: "Readex Pro", sans-serif;
  font-size: 20px;
  font-weight: 700;
}

.nav-cnt-right-profile-right-info {
  font-family: "Readex Pro", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: rgba(113, 117, 121, 1);
}
