.radioButton label > input[type="radio"] {
    top: 0;
    left: 0;
    cursor: inherit;
    margin: 0;
    opacity: 0;
    z-index: 1;
    position: absolute;
    background: none;
}

.radioButton label > input[type="radio"] + *::before {
    content: "";
    display: inline-block;
    vertical-align: bottom;
    width: 1rem;
    height: 1rem;
    margin-right: 0.3rem;
    border-radius: 50%;
    border-style: solid;
    border-width: 0.1rem;
    border-color: gray;
  }

.radioButton label > input[type="radio"]:checked + *::before {
    background: radial-gradient(teal 0%, teal 40%, transparent 50%, transparent);
    border-color: teal;
  }
.radioButton label > input[type="radio"]:checked + * {
    color: teal;
  }
/*for chrom /safari... */
.phoneNumber_only_number::-webkit-outer-spin-button,
.phoneNumber_only_number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* for Firefox */
.phoneNumber_only_number[type=number] {
  -moz-appearance: textfield;
}