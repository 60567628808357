/* @import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap"); */

/* font-family: 'Readex Pro', sans-serif; */
/* font-family: 'Readex Pro', sans-serif; */

@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&display=swap");

.elementLeft .inputLabel {
  padding: 0;
  margin: 0;
  margin-top: 1vh;
  font-size: 17px;
  font-family: "Readex Pro", sans-serif;
  font-weight: 500;
  text-align: left;
  color: #51516b;
  line-height: 18px;
  letter-spacing: -0.09px;
  margin-bottom: 0.5vw;
}

.rowContent .elementLeft .inputBar {
  width: 30vw;
  margin-top: "1vw";
  height: 32px;
  background: #ffffff;
  border: 1.44px solid #dfdfdf;
  border-radius: 4px;
  padding: 0;
  margin: 0;
  padding-left: 1vw;
  font-size: 17px;
}

.rowContent .elementLeft .inputBar:focus {
  width: 30vw;
  height: 32px;
  background: #ffffff;
  border: 1.44px solid #007aff;
  border-radius: 4px;
  padding: 0;
  margin: 0;
  padding-left: 1vw;
  font-size: 17px;
}

.left .rowContent .elementRight {
  padding: 0;
  margin: 0;
  margin-left: 1.77vw;
  margin-top: 1vh;
}

.rowContent .elementRight .inputLabel {
  padding: 0;
  margin: 0;
  margin-top: 1vh;
  font-size: 17px;
  font-family: "Readex Pro", sans-serif;
  font-weight: 500;
  text-align: left;
  color: #51516b;
  line-height: 18px;
  letter-spacing: -0.09px;
  margin-bottom: 0.5vw;
}

.rowContent .elementRight .inputBar {
  width: 30vw;
  height: 32px;
  margin-top: 1vh;
  background: #ffffff;
  border: 1.44px solid #dfdfdf;
  border-radius: 4px;
  padding: 0;
  margin: 0;
  padding-left: 1vw;
  font-size: 17px;
}

.rowContent .elementRight .inputBar:focus {
  width: 30vw;
  height: 32px;
  margin-top: 1vh;
  background: #ffffff;
  border: 1.44px solid #007aff;
  border-radius: 4px;
  padding: 0;
  margin: 0;
  padding-left: 1vw;
  font-size: 17px;
}

.btn {
  justify-content: space-around;
  border: none;
  color: white;
  padding: 15px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;

  font-size: 16px;
}

.left .rowContent .elementRight .inputBar {
  width: 30vw;
  height: 32px;
  margin-top: 1vh;
  background: #ffffff;
  border: 1.44px solid #dfdfdf;
  border-radius: 4px;
  padding: 0;
  margin: 0;
  padding-left: 1vw;
  font-size: 17px;
}

.left .rowContent .elementRight .inputBar:focus {
  width: 30vw;
  margin-top: 1vh;
  height: 32px;
  background: #ffffff;
  border: 1.44px solid #007aff;
  border-radius: 4px;
  padding: 0;
  margin: 0;
  padding-left: 1vw;
  font-size: 17px;
}

.left .inputReset .inputBar {
  width: 30vw;
  height: 32px;
  background: #ffffff;
  border: 1.44px solid #7a7878;
  border-radius: 4px;
  padding: 0;
  margin-top: 1vh;
  margin: 0;
  padding-left: 1vw;
  font-size: 17px;
}

.rowContent .elementRight .inputBar {
  width: 30vw;
  height: 32px;
  background: #ffffff;
  border: 1.44px solid #9b9a9a;
  border-radius: 4px;
  padding: 0;
  margin: 0;
  padding-left: 1vw;
  font-size: 17px;
}

.rowContent .elementRight .inputBar:focus {
  width: 30vw;
  height: 32px;
  background: #ffffff;
  border: 1.44px solid #007aff;
  border-radius: 4px;
  padding: 0;
  margin: 0;
  padding-left: 1vw;
  font-size: 17px;
}

.content {
  padding: 0;
  margin: 0;
  padding-top: 2.1vw;
  padding-bottom: 2.1vw;
  padding-left: 2.5vw;
  padding-right: 2.5vw;
  background-color: #ffffff;
  margin-top: 2vw;
}

.items .left .pinInfo .info .label {
  padding: 0;
  margin: 0;
  font-size: 17px;
  font-family: "Readex Pro", sans-serif;
  font-weight: 500;
  text-align: left;
  color: #565658;
  line-height: 18px;
  letter-spacing: -0.09px;
  padding: 0;
  margin: 0;
  margin-bottom: 0.5vw;
}

.left .pinInfo .info .inputfield {
  width: 9vw;
  height: 32px;
  background: #715858;
  border: 1.44px solid #dfdfdf;
  border-radius: 4px;
  padding: 0;
  margin: 0;
  padding-left: 1vw;
  font-size: 17px;
}

.admin-course {
  background-color: #e5e5e5;
}

.admin-course-top {
  color: #00495f;
  margin: 0 2vw;
  height: 7vh;
  font-weight: 600;
  font-size: 22px;
  font-family: "Readex Pro", sans-serif;
  display: grid;
  align-items: center;
  justify-content: left;
}

.admin-course-main {
  margin: 0 2vw;
}

.admin-course-main-top {
  background-color: white;
  margin: 1vw 0 1vw 0;
  border-radius: 3px;
}

.admin-course-main-bottom {
  background-color: white;
  margin: 1vw 0 1vw 0;
  border-radius: 3px;
  padding: 1px;
  color: #2c343e;
}

#admin-course-main-top-id {
  display: grid;
  grid-template-columns: 25vw 1fr;
  font-weight: 500;
  font-size: 18px;
  color: #2c343e;
  font-family: "Readex Pro", sans-serif;
}

.admin-course-main-top-inputlable {
  height: 8vh;
  display: grid;
  justify-content: left;
  align-items: center;
  margin-left: 1vw;
}

.admin-course-main-top-input {
  width: 20vw;
  height: 4vh;
  border: 2px solid #d7d7d7;
  border-radius: 6px;
}

.admin-course-main-top-select {
  width: 20vw;
  height: 4vh;
  border: 2px solid #d7d7d7;
  border-radius: 6px;
}

.admin-course-main-top-inputlable textarea {
  width: 20vw;
  height: 4vh;
  border: 2px solid #d7d7d7;
  border-radius: 6px;
  height: 8vh;
}

.admin-course-main-top-coursduration {
  margin: 1vw 0 0 0;
}

.admin-course-main-bottom-label {
  margin: 1vw 1vw;
  font-weight: 500;
  font-size: 20px;
  color: #2c343e;
  font-family: "Readex Pro", sans-serif;
}

.admin-course-btn {
  font-family: "Readex Pro", sans-serif;
  width: 8vw;
  height: 4vh;
  font-weight: 600;
  font-size: 16px;
  border: none;
  border-radius: 3px;
  color: white;
}

.Role {
  border: 1px solid black;
  padding: 0.05vw 0.2vw 0.1vw 0.2vw;
  border-radius: 50%;
  margin-left: 4vw;
}

​.spanData {
  position: absolute;
  background: black;
  padding-left: 1vw;
  margin-top: -0.2vw;
  border-radius: 0px 5px 5px 0px;
  width: 45vw;
  color: white;
  margin-left: -0.3vw;
}

.hide {
  display: none;
}
