.admin-users {
  background-color: #e5e5e5;
}
.admin-users-main {
  display: grid;
  grid-template-columns: 19vw 1fr;
}
.admin-overdue-bottom-table-cnt-usr {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  padding: 1vw 2vw;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  left: 150px;
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #2710ba; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.bulkUser-row {
  display: grid;
  grid-template-columns: 1fr 2fr repeat(6, 1fr);
}
