/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap"); */

@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&display=swap");

.admin-dashboard-main-bottom-cnt {
  display: grid;
  grid-template-columns: 5vw 1fr;
  background-color: white;
  border-radius: 6px;
  padding: 0vw 1vw;
  /* height: 60px; */
}

.admin-dashboard-main-bottom-left-icon {
  display: grid;
  justify-content: center;
  margin-top: 0.7vw;
}

.admin-dashboard-main-bottom-right-btn {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.admin-dashboard-main-bottom-right-top {
  font-weight: 500;
  font-size: 16px;
  font-family: "Readex Pro", sans-serif;
  display: grid;
  justify-content: left;
  align-items: center;
  margin: 1vh 0;
}

.admin-dashboard-main-bottom-right-btm {
  margin: 1vh 0;
  font-weight: 400;
  font-size: 12px;
  display: grid;
  justify-content: left;
  align-items: center;
  font-family: "Readex Pro", sans-serif;
  color: rgba(113, 117, 121, 0.5);
}
