.outer-details-container {
  /* border: 2px solid gray; */
  margin: 54px;
}

.bigger-container-header {
  border-radius: 5px;
  border: 2px solid lightgray;
  background-color: #241887;
  color: aliceblue;
  font-weight: bold;
  padding: 10px 20px;
  font-size: 25px;
  text-align: center;
}

.user-data-bigger-container {
  font-weight: bold;
  padding: 14px;
  margin-top: 16px;
  margin-bottom: 8px;
}
.user-data-container {
  display: flex;
  justify-content: space-between;
  padding: 0px 14px;
}

.user-data-input {
  border: 2px solid lightgray;
  border-radius: 5px;
  padding: 0px 5px;
  margin-left: 10px;
}

.username-container {
  display: flex;
  /* justify-content: space-between; */
  padding: 0px 14px;
}

.preview-container {
  /* text-right pr-2 my-4 */
  text-align: right;
  margin: 25px 0px;
  padding-right: 26px;
}
.create-new-box-pdf {
  text-align: right;
  margin-top: 15px;
  margin-right: 30px;
}
.srollbar_div {
  overflow-y: scroll;
  background-color: #e5e5e5;
}
