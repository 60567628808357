/* @import url("https://fonts.googleapis.com/css2?family='Readex Pro', sans-serif:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap"); */

@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&display=swap");

.admin-overdue-bottom-table-cnt-user-view {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 1vw 2vw;
}
.admin-overdue-bottom-table-cnt-user-view-ex {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  padding: 1vw 2vw;
}
.admin-overdue-bottom-table-cnt-user-view-t {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 1vw 2vw;
}

.admin-user-head {
  font-family: "Readex Pro", sans-serif;
  font-weight: 600;
  font-size: 22px;
  color: white;
  margin: 2vw 2vw 0 2vw;
  margin-bottom: -2vw;
  background-color: #f18040;
  width: 20vw;
  text-align: center;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.admin-overdue-mid-user-view {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  padding: 1vw 2vw;
}

.admin-overdue-mid-user-view-txt-dim {
  color: #717579;
  font-weight: 400;
  font-size: 16px;
  font-family: "Readex Pro", sans-serif;
}

.admin-overdue-mid-user-view-cnt {
  margin: 1vw;
}
.modalDiv {
  margin: 1vw 2vw 0.5vw 2vw;
  font-family: "Readex Pro", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}
/* .modalDiv .disableInput{
  border: none;
    width: 9vw;
    height: 1.5vw;
    border-radius: 2px;
} */
.modalButton {
  background: #007c84;
  border-radius: 5px;
  font-family: "Readex Pro", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  margin-left: 25px;
  line-height: 16px;
  width: 95px;
  height: 28px;
  color: #ffffff;
}
.label {
  font-family: "Readex Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */

  /* Font dark color */

  color: #2c343e;
}
.Modal {
  overflow: scroll;
  /* width: 900px; */
  height: 650px;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.loading-spinner {
  left: 150px;
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #2710ba; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
