@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&display=swap");

.courseItem-container {
  width: 100%;
  box-shadow: 4px 4px 10px rgba(0, 118, 135, 0.05),
    6px 6px 40px rgba(0, 118, 135, 0.1);
  margin-left: 10px;
  margin-right: 10px;
  background-color: #fff;
}

.courseItem-pdfContainer {
  /* width: 100%; */
  height: auto;
  text-align: center;
  background-color: #fff;
}
.courseItem-document {
  max-width: 100%;
}

.courseItem-pdfTopbar {
  /* width: 100%; */
  background: #ffffff;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 5px;
}
.courseItem-pdfTopbar-zoomInfo {
  display: flex;
  align-items: center;
  margin-right: auto;
  margin-left: 10px;
}
.courseItem-pdfTopbarText {
  font: 1em "Readex Pro", sans-serif;
  color: #00495f;
  margin-right: 20px;

  align-items: center;
}
.courseItem-mainpdf {
  box-sizing: border-box;
  /* width: 100%; */
  /* overflow: scroll; */
  overflow-y: scroll;
  /* max-width: 900px; */
  height: 80vh;
  background-color: #fff;
}
.courseitem-pdfPage {
  display: inline-block;
  max-width: 100%;
}
.customControls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.customAudioSeek {
  -webkit-appearance: none;

  width: 90%;
  height: 0.5em;
  background-color: #e5e5e5;
  border-radius: 5px;
  background-size: 0% 100%;
  background-image: linear-gradient(to right, rgb(0, 120, 72), rgb(0, 69, 119));
  background-repeat: no-repeat;
}
.customAudioSeek::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: all 0.1s;
  background-color: #013545;
}

.customAudioSeek::-webkit-slider-thumb:hover {
  background-color: #00495f;
}

.customAudioSeek:hover::-webkit-slider-thumb {
  opacity: 1;
}
.customAudioSeek::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.videoPlayer-container {
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.videoPlayer-playPause {
  width: 2em;
  height: 2em;
  margin-left: 10px;
}
.videoPlayer-controls {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
}
.videoPlayer-resetProgress {
  color: white;
  border: none;
  padding: 5px;
  margin-left: auto;
  margin-right: 10px;
  background: linear-gradient(90deg, #007848 0%, #004577 105.46%);
  border-radius: 4px;
}
.htmlItem-container {
  width: 100%;
  text-align: center;
  background-color: #fff;
}
.htmlItem-iframe {
  border: none;
  width: 100%;
  height: 500px;
}
.markCompleted {
  font-family: "Readex Pro", sans-serif;
  display: flex;
  align-items: center;
  color: #00495f;
  padding: 0.75em;
}

.quizItem-container {
  width: 100%;
  padding: 20px;
  text-align: center;
}
.quizItem-instructions {
  background: linear-gradient(90deg, #007848 0%, #004577 100%);
  border-radius: 5px;
  font-family: "Readex Pro", sans-serif;
  font-size: 1.25em;
  font-weight: 700;
  width: 100%;
  height: auto;
  padding: 0.575em;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.quizItem-overview {
  display: flex;
  justify-content: space-around;
  font-family: "Readex Pro", sans-serif;
  font-weight: 600;
  color: #007c84;
}
.quizItem-overview-item {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 1.5em;
}

.quizItem-verticalLine {
  border: 1px solid #007c84;
  height: auto;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.quizItem-hr {
  border: 1px solid #007c84;
}
.quizItem-list {
  font-family: "Readex Pro", sans-serif;
  font-weight: 500;
  color: #007c84;
  margin-top: 3.5em;
  text-align: left;
  list-style: inside;
}
.quizItem-item {
  font-family: "Readex Pro", sans-serif;
  font-weight: 500;
  font-size: 1em;
  margin-bottom: 1.2em;
}

.quizItem-button {
  margin-top: 1em;
  background: linear-gradient(90deg, #007848 0%, #004577 105.46%);
  border: none;
  font-family: "Readex Pro", sans-serif;
  font-weight: 700;
  padding: 0.5em 1em 0.5em 1em;
  border-radius: 5px;
  color: #fff;
}

.quiziEnd-heading {
  font-family: "Readex Pro", sans-serif;
  color: #007c84;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
}
.quiziEnd-score {
  font-family: "Readex Pro", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  /* identical to box height */

  color: #00495f;
}
.quiziEnd-subheading {
  font-family: "Readex Pro", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #007c84;
}
.quiziEnd-subheading-detail {
  display: inline;
  font-family: "Readex Pro", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #00495f;
}

.node-container {
  width: 100%;
  padding: 20px;
  text-align: center;
  font-family: "Readex Pro", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  background: linear-gradient(90deg, #007848 0%, #004577 105.46%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
