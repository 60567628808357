@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&display=swap");

.admin-certificate {
  background-color: #e5e5e5;
}

.admin-certificate-main {
  display: grid;
  grid-template-columns: 19vw 1fr;
}

.search {
  font-family: "Readex Pro", sans-serif;
  grid-template-columns: 2vw 1fr;
  height: 5vh;
  width: 7vw;
  border: none;
  border-radius: 6px;
}

.searchDiv {
  display: grid;
  place-items: center;
  height: 100%;
  font-weight: 700;
  font-size: 1vw;
  color: white;
}

.filter {
  font-family: "Readex Pro", sans-serif;
  grid-template-columns: 2vw 1fr;
  display: grid;
  height: 5vh;
  width: 9vw;
  border: none;
  border-radius: 6px;
}

.filterDiv {
  display: grid;
  place-items: center;
  height: 100%;
  font-weight: 700;
  font-size: 1vw;
  color: white;
}

.head {
  font-family: "Readex Pro", sans-serif;
  color: white;
  width: 15vw;
  font-weight: 400;
  font-size: 22px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  margin: 0 2vw;
  padding-left: 1vw;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  left: 150px;
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  /* Light grey */
  border-top: 10px solid #2710ba;
  /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
